<template>
  <div class="face-auth-result">
    <div class="face-auth-result-body" @click="leftClick">
      <div><p>人脸核身操作完成，去看课</p></div>
      <div><p class="face-auth-result-confirm">确定</p></div>
    </div>
    <div class="FaceDialog">

    </div>
  </div>
</template>
<script>

export default {
  name: 'faceAuthResult',
  data() {
    return {
      backUrl: '',
    }
  },
  methods: {
    pushHistory() {
      history.pushState(null, null, document.URL)
    },
    leftClick() {
      this.backUrl = this.$route.query.backUrl
      if (!this.backUrl) {
        // 没有返回跳转的路由就直接去首页
        this.$router.push({path: '/'})
      } else {
        this.$router.push({path: this.backUrl})
      }
    }
  },
  created() {
    history.pushState(null, null, document.URL)
    window.addEventListener('popstate', this.pushHistory, false)
  },
  mounted() {
    // Dialog.alert({
    //   message: '人脸核身操作完成，是否继续？',
    // }).then(() => {
    //   this.leftClick()
    // })
    // const timer = setTimeout(() => {
    //   clearTimeout(timer)
    //   this.pushHistory()
    // }, 1000)
  },
  destroyed() {
    window.removeEventListener('popstate', this.pushHistory, false)
  }
}
</script>
<style scoped>
p {
  font-size: 16px;
}
.face-auth-result{
  position: relative;
  width: 100vw;
  height: 100vh;
}
.face-auth-result-body{
  position: absolute;
  z-index: 9;
  left: 10%;
  width: 80%;
  top: 40%;
  background-color: #ffffff;
  border-radius: 10px;
  /*position: absolute;*/
  /*width:400px;*/
  /*height:200px;*/
  /*left:50%;*/
  /*top:50%;*/
  /*margin-left:-200px;*/
  /*margin-top:-100px;*/
  /*border:0px solid #00F*/
  /*display: -webkit-flex;*/
  display: flex;
  -webkit-align-items: center;
  flex-direction: column;
  align-items: center;

}
.face-auth-result-confirm{
  width: 100%;
  color: red;
}
.FaceDialog{
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,.6);
  z-index: -1;
}
</style>
